<script setup>
    import * as sanitizeHtml from 'sanitize-html'
    import {ref, reactive, computed, onMounted, onBeforeUnmount } from 'vue';
    import ModalForm from './ModalForm.vue';

    let newText = ref(null);
    let pageType = ref(pageValueType)
    let topKeyword = ref('');
    let keyword = ref('');
    let keywordPre = ref('');
    let lists = ref(movieData);
    let selectCategory = ref(['ALL']);
    let selectTag = ref([]);
    let selectWL = ref([]);
    let noData = ref(true);
    let sortSelected = ref("date_new");
    let newMovies = ref(newMovie);
    let pickupDatas = ref(pickupData);
    // let mainImgURL = ref(mainImageURL);
    // let dateVisibleFlg = ref(dateVisible);
    // let sortOnFlg = ref(false);//sort機能を使用するかどうか(bool)
    //let newMovies = ref(newMovie);
    //let pickupDatas = ref(pickupData);
    let categoryList = ref(categoryData);
    let tagList = ref(tagData);
    let wlList = ref(WinLoseData);
    let categorySelectboxOpen = ref(false);
    let searchNumber = ref(0);//検索件数
    let pagingMovieMaxNumber = ref(pageMaxNumber); //ページ動画表示数
    let pagingTotalNumber = ref(1); //ページ数
    let pagingCurrentPage = ref(1); //現在のページ
    let pagingArray = ref([]); //ページング表示配列(0--「...」)
    let newTextMoreButton = ref({
        isMore: false,
        isOpen: false,
        height: null
    });
    let newTextMoreButtonClass = ref({
        'more': false,
        'open': false,
        'close': false
    });
    let newMovieMoreHeight = ref(76);
    let modalData = ref({
        on: false,
        id: "",
        title: "",
        text: "",
        tags: "",
        category: "",
        date: ""
    });
    let mobileMenuOpen = ref(false);

    const getHref = (query_key,word) => {
        let href = "./search/" + ((query_key == null)?"":"?"+query_key+"="+encodeURI(word));
        return href;
    }
    const getImagePath = (filepath) => {
        let imgpath = filepath;
        return imgpath;
    }
    const getNoData = () =>{
        if(noData.value) return true;
        else return false;
        // return true;
    }
    const getViewData = (value) => {
        return value;
    }
    const getDeleteArrayIndex = (array,delete_key) => {
        for(var i=0;i<array.length;i++) {
            if(array[i] == delete_key) return i;
        }
        return null;
    }
    const getSearchNumber = () => {
        return searchNumber.value;
    }
    const getCurrentSearchNumber = () => {
        // console.log("現在のページ数"+this.pagingCurrentPage);
        // console.log("全ページ数"+this.pagingTotalNumber);
        // console.log("ページ表示数"+this.pagingMovieMaxNumber);
        // console.log("全件数"+this.searchNumber);
        var from = (pagingMovieMaxNumber.value * (pagingCurrentPage.value - 1)) + 1;
        var a = pagingCurrentPage.value * pagingMovieMaxNumber.value;
        var to = (a >= searchNumber.value)?searchNumber.value:a;
        return from +"-"+ to;
    }
    const getCountVisibleCheck = () => {
        return (searchNumber.value <= 0)?false:true;
    }
    const setCategory = (category_key) => {
        resetPaging();
        if(category_key.value == "ALL") selectCategory.value = ["ALL"];
        else {
            if(!getCategory(category_key)) {
                selectCategory.value = [];
                selectCategory.value.push(category_key);
            }
        }
        return false;

        if(category_key == "ALL") selectCategory.value = ["ALL"];
        else {
            if(selectCategory.value[0] == "ALL") selectCategory.value = [];
            if(!getCategory(category_key)) {
                selectCategory.value.push(category_key);
            }else {
                var index = getDeleteArrayIndex(selectCategory.value,category_key);
                selectCategory.value.splice(index,1);
                if(selectCategory.value.length <= 0) selectCategory.value = ["ALL"];
            }
        }
        
    }
    const getCategory = (category_key) => {
        for(var i=0;i<selectCategory.value.length;i++) {
            if(selectCategory.value[i] == category_key) return true;
        }
        // if(selectCategory.value == category_key) return true;
        return false;
    }
    const getCategoryHTML = (category_str) => {
        var categoryHTML = "";
        if(category_str == "") categoryHTML = "<span>&nbsp;</span>";
        else {
            var ar = (category_str.indexOf(",") == (-1))?[category_str]:category_str.split(",");
            for(var i=0;i<ar.length;i++) {
                categoryHTML += (i == 0)?"<a href='" + getHref("category",ar[i]) + "'>" +ar[i]+"</a>":"、"+"<a href='" + getHref("category",ar[i]) + "'>" + ar[i]+"</a>";
            }
        }
        // console.log("category_set:"+category_str);
        // console.log(categoryHTML)
        return categoryHTML;
    }
    const getCategoryListHTML = (category_str) => {
        // var tagData = {id:"aaaaaa",title:"title"};
        // console.log("category_str/"+category_str);
        var categoryData = [];
        var ar = (category_str.indexOf(",") == (-1))?[category_str]:category_str.split(",");
        // console.log(ar)
        for(var i=0;i<ar.length;i++) {
            categoryData.push({category_key:ar[i], category_name: ar[i]});
        }
        // console.log(categoryData);
        return categoryData;
    }
    const setSelectCategory = (Category_key) => {
        resetSettingData();
        setCategory(Category_key);
    }
    const getSelectCategoryName = () => {
        return selectCategory.value[0];
    }
    const getSelectboxCategoryStagus = () => {
        return categorySelectboxOpen.value;
    }
    const setSelectboxCategoryStatus = () => {
        categorySelectboxOpen.value = !categorySelectboxOpen.value;
    }
    const setWL = (wl_key) => {
        resetPaging();
        if(!getWL(wl_key)) {
            selectWL.value.push(wl_key);
        }else {
            var index = getDeleteArrayIndex(selectWL.value,wl_key);
                selectWL.value.splice(index,1);
                if(selectWL.value.length <= 0) selectWL.value = [];
        }
    }
    const getWL = (wl_key) => {
        for(var i=0;i<selectWL.value.length;i++) {
            if(selectWL.value[i] == wl_key) return true;
        }
        // if(this.selectWL == wl_key) return true;
        return false;
    }
    const setTag = (tag_key) => {
        resetPaging();
        if(!getTag(tag_key)) {
            selectTag.value.push(tag_key);
        }else {
            var index = getDeleteArrayIndex(selectTag.value,tag_key);
                selectTag.value.splice(index,1);
                if(selectTag.value.length <= 0) selectTag.value = [];
        }
    }
    const getTag = (tag_key) => {
        for(var i=0;i<selectTag.value.length;i++) {
            if(selectTag.value[i] == tag_key) return true;
        }
        // if(this.selectTag == tag_key) return true;
        return false;
    }
    const getTagHTML = (tag_str) => {
        var tagHTML = "";
        var ar = (tag_str.indexOf(",") == (-1))?[tag_str]:tag_str.split(",");
        for(var i=0;i<ar.length;i++) {
            tagHTML += "<a href='" + getHref("tag",ar[i]) + "'>" +ar[i]+"</a>";
        }
        return tagHTML;
    }
    const getTagListHTML = (tag_str) => {
        // var tagData = {id:"aaaaaa",title:"title"};
        var tagData = [];
        var ar = (tag_str.indexOf(",") == (-1))?[tag_str]:tag_str.split(",");
        for(var i=0;i<ar.length;i++) {
            // tagData.push({tag_key:ar[i], tag_name: "#"+ar[i]});
            tagData.push({tag_key:ar[i], tag_name: ar[i]});
        }
        return tagData;
    }
    const setSelectTag = (tag_key) => {
        resetSettingData();
        setTag(tag_key);
    }
    const getThumbnailURL = (movie_key) => {
        return "https://img.youtube.com/vi/"+movie_key+"/mqdefault.jpg";
    }
    const openModal = (data) => {
        modalData.value = {
            on: true,
            id: data.id,
            title: data.title,
            text: data.text,
            tags: data.tags,
            category: data.category,
            date: data.date
        };

        setModalBody();
    }
    const modalClose = () => {
        modalData.value.on = false;

        unsetModalBody();

    }
    const setModalBody = () => {
        var winScrollH = $(window).scrollTop();
        $("body").addClass("modal").css({top:"-"+winScrollH+"px"});
        
    }
    const unsetModalBody = () => {
        var $body = $("body");
        var winScrollH = $body.css("top");
        winScrollH = winScrollH.replace(/[^0-9]/g, '');
        
        $body.removeClass("modal");
        $(window).scrollTop(winScrollH);
        $body.css({top:"auto"});
    }
    const modalCloseSelectTag = (tag_key) => {
        modalClose();
        resetSettingData();
        setSelectTag(tag_key);
    }
    const modalCloseSelectCategory = (category_key) => {
        modalClose();
        resetSettingData();
        setSelectCategory(category_key);
    }
    const getYoutubePath = (key) => {
        return "https://www.youtube.com/embed/" + key;
    }
    const resetSettingData = () => {
        selectCategory.value = ["ALL"];
        selectTag.value = [];
        keyword.value = "";
    }
    const setPage = (page) => {
        pagingCurrentPage.value = page;
        // console.log("forceupdate");
        // $forceUpdate();
    }
    const getPagingStatus = (page) => {
        if(page == pagingCurrentPage.value) return true;
        else return false;
    }
    const resetPaging = () => {
        pagingCurrentPage.value = 1;
    }
    const pagingNext = () => {
        let nPage = pagingCurrentPage.value + 1;
        if(nPage <= pagingTotalNumber.value) pagingCurrentPage.value += 1;
    }
    const pagingBack = () => {
        let bPage = pagingCurrentPage.value - 1;
        if(bPage > 0) pagingCurrentPage.value -= 1;
    }
    const getPagingNextCheck = () => {
        if(pagingTotalNumber.value > pagingCurrentPage.value) return true;
        else return false;
    }
    const getPagingBackCheck = () => {
        if(pagingCurrentPage.value > 1) return true;
        else return false
    }
    const getPagingDotCheck = (str) => {
        if(str === 0) return true;
        else return false;
    }
    const getDisplayCheck = (data) => {
        if(!data || data == "") return false;
        return true;
    }
    const getMobileMenuOpen = () => {
        //console.log(mobileMenuOpen.value);
        return mobileMenuOpen.value;
    }
    const setMobileMenuOpen = (tf) => {
        mobileMenuOpen.value = tf;
    }
    const mobileMenuNoClose = (e) => {
        e.stopPropagation();
    }
    const eventStop = () => {
        return true;
    }
    const initNewTextMoreButton = () => {
        if(typeof(newText.value) != "undefined") {
            var th = this;
            var h = newText.value[0].clientHeight;//console.log("h:"+h);
            newTextMoreButton.height = h;
            if(h > newMovieMoreHeight) {
                newTextMoreButton.isMore = true;//console.log(newTextMoreButtonClass.more);
                newTextMoreButtonClass.more = true;//console.log(newTextMoreButtonClass.more);
                newTextMoreButtonClass.close = true;
                // var el = document.querySelector('.m-cardbox-02-text');
                // el.addEventListener('transitionend', function() {
                //     th.setNewTextHeight();
                //     th.resizeNewTextHeight();
                // })
            }
        }  
    }
    const resizeNewTextHeight = () => {
        if(typeof(newText.value) != "undefined" && newTextMoreButton.isOpen) {
            var th = this;
            var h = newText.value[0].clientHeight;
            newTextMoreButton.height = h;
        }  
    }
    const setNewTextHeight = () => {
        if(typeof(newText.value) != "undefined") {
            var element = newText.value[0];
            if(newTextMoreButton.isOpen) {
                element.style.height = "auto";
            }
        } 
    }
    const exeMoreButton = () => {
        var element = newText.value[0];
        newTextMoreButton.isOpen = true;
        element.style.height = newTextMoreButton.height + "px";

        
    }
    const exeMoreCloseButton = () => {
        var element = newText.value[0];
        element.style.height = newTextMoreButton.height + "px";
        element.style.height = newMovieMoreHeight + "px";
        newTextMoreButton.isOpen = false;
        
    }
    const moveToAllContents = () => {
        VueScrollTo.scrollTo("#all-contents",1000);
    }






    
    
    
    
    
    
    
    
    
    
    const newTextMoreButtonObject = computed(() => {
        // console.log('more:'+this.newTextMoreButton.isMore);
        // console.log('open:'+this.newTextMoreButton.isOpen)
        // console.log('close:'+!this.newTextMoreButton.isOpen)
        return {
            'more': newTextMoreButton.isMore,
            'open': newTextMoreButton.isOpen,
            'close': !newTextMoreButton.isOpen
        }
    });
    const newLists = computed(() => {
        let in_lists = [];
        let in_sortlists = [];
        let in_newLists = newMovies.value;
        let checkNewData = function(movie_id) {
            for(let j=0;j<in_newLists.length;j++) {
                if(in_newLists[j] == movie_id) return true;
            }
            return false;
        }
        let sortList = function(li) {
            li.sort(function(a,b) {
                return (a.date < b.date ? 1 : -1);
            });
            return li;
        }
        for (let i=0;i<lists.value.length;i++) {
            if(checkNewData(lists.value[i].id)) in_lists.push(lists.value[i]);
            in_sortlists.push(lists.value[i]);
        }
        if(in_lists.length <= 0) {
            in_lists = [sortList(in_sortlists)[0]];
            return in_lists;
        }else {
            return in_lists;
        }
    })
    const pickupLists = computed(() => {
        let in_lists = [];
        
        let in_pickupLists = pickupDatas.value;
        let in_mList = lists.value;
        let checkPickup = function(movie_id) {
            // for(let j=0;j<pickupLists.length;j++) {
            //     if(pickupLists[j] == movie_id) return true;
            // }
            // return false;
            for(let j=0;j<in_mList.length;j++) {
                if(in_mList[j].id == movie_id) return in_mList[j];
            }
            return false;
        }
        // for (let i=1;i<this.lists.length;i++) {
        //     // console.log(this.lists[i].id);
        //     if(checkPickup(this.lists[i].id)) lists.push(this.lists[i]);
            
        // }
        for (let i=1;i<in_pickupLists.length;i++) {
            // console.log(this.lists[i].id);
            let ck = checkPickup(in_pickupLists[i])
            if(ck) in_lists.push(ck);
            
        }

        return in_lists;
    });
    const filteredLists = computed(() => {
            if(keywordPre.value != keyword.value) resetPaging();

            keywordPre.value = keyword.value;
            var eisuuHankakuFromZenkaku = function(str)
            {
                var halfVal = str.replace(/[！-～]/g,
                    function( tmpStr ) {
                        return String.fromCharCode( tmpStr.charCodeAt(0) - 0xFEE0 );
                    }
                );
                return halfVal.replace(/”/g, "\"")
                .replace(/’/g, "'")
                .replace(/‘/g, "`")
                .replace(/￥/g, "\\")
                .replace(/　/g, " ")
                .replace(/〜/g, "~");
            }
            var kanaZenkakuFromHankaku = function (str)
            {
                var kanaMap = {
                    "ｶﾞ": "ガ", "ｷﾞ": "ギ", "ｸﾞ": "グ", "ｹﾞ": "ゲ", "ｺﾞ": "ゴ",
                    "ｻﾞ": "ザ", "ｼﾞ": "ジ", "ｽﾞ": "ズ", "ｾﾞ": "ゼ", "ｿﾞ": "ゾ",
                    "ﾀﾞ": "ダ", "ﾁﾞ": "ヂ", "ﾂﾞ": "ヅ", "ﾃﾞ": "デ", "ﾄﾞ": "ド",
                    "ﾊﾞ": "バ", "ﾋﾞ": "ビ", "ﾌﾞ": "ブ", "ﾍﾞ": "ベ", "ﾎﾞ": "ボ",
                    "ﾊﾟ": "パ", "ﾋﾟ": "ピ", "ﾌﾟ": "プ", "ﾍﾟ": "ペ", "ﾎﾟ": "ポ",
                    "ｳﾞ": "ヴ", "ﾜﾞ": "ヷ", "ｦﾞ": "ヺ",
                    "ｱ": "ア", "ｲ": "イ", "ｳ": "ウ", "ｴ": "エ", "ｵ": "オ",
                    "ｶ": "カ", "ｷ": "キ", "ｸ": "ク", "ｹ": "ケ", "ｺ": "コ",
                    "ｻ": "サ", "ｼ": "シ", "ｽ": "ス", "ｾ": "セ", "ｿ": "ソ",
                    "ﾀ": "タ", "ﾁ": "チ", "ﾂ": "ツ", "ﾃ": "テ", "ﾄ": "ト",
                    "ﾅ": "ナ", "ﾆ": "ニ", "ﾇ": "ヌ", "ﾈ": "ネ", "ﾉ": "ノ",
                    "ﾊ": "ハ", "ﾋ": "ヒ", "ﾌ": "フ", "ﾍ": "ヘ", "ﾎ": "ホ",
                    "ﾏ": "マ", "ﾐ": "ミ", "ﾑ": "ム", "ﾒ": "メ", "ﾓ": "モ",
                    "ﾔ": "ヤ", "ﾕ": "ユ", "ﾖ": "ヨ",
                    "ﾗ": "ラ", "ﾘ": "リ", "ﾙ": "ル", "ﾚ": "レ", "ﾛ": "ロ",
                    "ﾜ": "ワ", "ｦ": "ヲ", "ﾝ": "ン",
                    "ｧ": "ァ", "ｨ": "ィ", "ｩ": "ゥ", "ｪ": "ェ", "ｫ": "ォ",
                    "ｯ": "ッ", "ｬ": "ャ", "ｭ": "ュ", "ｮ": "ョ",
                    "｡": "。", "､": "、", "ｰ": "ー", "｢": "「", "｣": "」", "･": "・"
                };
                var reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
                return str.replace(reg, function (match) {
                            return kanaMap[match];
                        }).replace(/ﾞ/g, "゛").replace(/ﾟ/g, "゜").replace(/　/g," ");
            };
            let doRefine = function(li,key) {
                // let hankakuSPFromZenkaku = key.reqlace(/　/g," ");
                // console.log(key);
                key = key.replace(/^\s+|\s+$/g,'').replace(/ +/g,' ');
                //console.log(key);
                let keyArray = (key.indexOf(" ") !== (-1))?key.split(" "):[key];
                // console.log(keyArray);
                // console.log(li);
                for(let i=0;i<keyArray.length;i++) {
                    //console.log(li+"/"+keyArray[i]);
                    if(li.indexOf(keyArray[i]) == (-1)) return false;
                }
                return true;

            }
            let checkSearch = function(li_title,select_keyword) {
                if(doRefine(li_title,select_keyword)) return true;
                return false;
            }
            let checkTag = function(li_tag,select_tag) {
                if(select_tag.length > 0) {
                    for(let k=0;k<select_tag.length;k++){
                        if(doRefine(li_tag,select_tag[k])) return true;
                    }
                    return false;
                }else return true;
            }
            let checkWL = function(li_WL,select_WL) {
                if(select_WL.length > 0) {
                    for(let k=0;k<select_WL.length;k++){
                        if(doRefine(li_WL,select_WL[k])) return true;
                    }
                    return false;
                }else return true;
            }
            let checkCategory = function(li_category,select_category) {
                if(select_category[0] != "ALL") {
                    for(let j=0;j<select_category.length;j++) {
                        if(doRefine(li_category,select_category[j])) {
                            return true;
                            // lists.push(list);console.log("A");
                            // break;
                        }
                        // if((j+1) == selectCategory.length)
                    }
                    return false;
                    
                }else {
                    return true;
                }
            }
            let changeStrType = function(str) {
                let changeSTR = str;
                changeSTR = kanaZenkakuFromHankaku(changeSTR);
                changeSTR = eisuuHankakuFromZenkaku(changeSTR);
                return changeSTR;
            }
            let sortList = function(li,sortCategory) {

                if(sortCategory == "date_new") { //日付（新しい順）
                    li.sort(function(a,b) {
                        return (a.date < b.date ? 1 : -1);
                    });
                }else if(sortCategory == "date_old") { //日付（古い順）
                    li.sort(function(a,b) {
                        return (a.date > b.date ? 1 : -1);
                    });
                }else if(sortCategory == "title_asc") { //タイトル（昇順）
                    li.sort(function(a,b) {
                        return (a.title > b.title ? 1 : -1);
                        // return (Number(a.title.match(/(\d+)/g)[0]) - Number((b.title.match(/(\d+)/g)[0])));
                    });
                }else { //タイトル（降順）
                    li.sort(function(a,b) {
                        return (a.title < b.title ? 1 : -1);
                    });
                }


                return li;
            }
            let in_lists = [];
            let in_keyword = keyword.value.toLowerCase();
                in_keyword = kanaZenkakuFromHankaku(in_keyword);
                in_keyword = eisuuHankakuFromZenkaku(in_keyword);
            let in_selectCategory = selectCategory.value;
            let in_selectWL = selectWL.value;
            let in_selectTag = selectTag.value;
                // doRefine(null,keyword);
            // for(let i in lists) {
            // console.log(selectCategory);
            // console.log(selectTag);
            for (let i=0;i<lists.value.length;i++) {
                let in_list = lists.value[i];
                //console.log(list.title +"//"+keyword);
                let in_category = changeStrType(in_list.category);
                let in_title = changeStrType(in_list.title);
                let in_wl = changeStrType(in_list.wl);
                let in_tag = changeStrType(in_list.tags);
                let in_text = changeStrType(in_list.text);
                let in_date = changeStrType(in_list.date);
                // let category = eisuuHankakuFromZenkaku(list.category);
                // let title = kanaZenkakuFromHankaku(list.title);
                // title = eisuuHankakuFromZenkaku(title);
                // let tag = eisuuHankakuFromZenkaku(list.tags);
                // let searchSTR = category + " " + title + " " + tag + " " + text + " " + date;
                // searchSTR = searchSTR.toLowerCase();
                // searchSTR = kanaZenkakuFromHankaku(searchSTR);
                // searchSTR = eisuuHankakuFromZenkaku(searchSTR);
                let in_searchSTR = in_list.searchSTR;

                // if(list.tags.indexOf(keyword) !== (-1)) {
                    //検索でHIT
                    //TAGでHIT
                    //CategoryでHIT
                    // console.log(checkSearch(title,keyword));
                // if(checkSearch(title,keyword) && checkTag(tag,selectTag) && checkCategory(category,selectCategory)) lists.push(list);
                // console.log("searchSTR/"+searchSTR);
                // console.log("keyword/"+keyword);
                // console.log("tag/"+tag);
                // console.log("selectTag/"+selectTag);
                // console.log("category/"+category);
                // console.log("selectCategory/"+selectCategory);

                // console.log("checkSearch:"+checkSearch(searchSTR,keyword));
                // console.log("checkTag:"+checkTag(tag,selectTag));
                // console.log("checkCategory:"+checkCategory(category,selectCategory));
                //console.log(checkWL(in_wl,in_selectWL));
                if(checkSearch(in_searchSTR,in_keyword) && checkTag(in_tag,in_selectTag) && checkWL(in_wl,in_selectWL) && checkCategory(in_category,in_selectCategory)) in_lists.push(in_list);

            }
            if(in_lists.length>0) noData.value = false;
            else noData.value = true;
            // lists = lists.reverse();
            // return  lists;
            in_lists = sortList(in_lists,sortSelected.value);
            // if(sortOnFlg.value) {
            //     in_lists = sortList(in_lists,sortSelected.value);
            // }else {
            //     in_lists = sortList(in_lists,"date_old");
            // }
            
            // console.log(lists);
            //paging
        // pagingMovieMaxNumber: pageMaxNumber, //ページ動画表示数
        // pagingTotalNumber: 1, //ページ数
        // pagingCurrentPage: 1, //現在のページ
            searchNumber.value = in_lists.length;
            pagingTotalNumber.value = ((searchNumber.value % pagingMovieMaxNumber.value) != 0)? parseInt(searchNumber.value / pagingMovieMaxNumber.value) + 1 : searchNumber.value / pagingMovieMaxNumber.value;
            let in_pArray = [];
            if(pagingTotalNumber.value <= 7) {
                for(var i=1;i<=pagingTotalNumber.value;i++) in_pArray.push(i);
            }
            else {
                in_pArray.push(1);
                if(pagingCurrentPage.value - 1 >= 3) in_pArray.push(0);
                if(pagingCurrentPage.value === 1) in_pArray.push(2);
                else if(pagingCurrentPage.value === pagingTotalNumber.value) in_pArray.push(pagingTotalNumber.value - 1);
                else {
                    if(pagingCurrentPage.value - 1 !== 1) in_pArray.push(pagingCurrentPage.value - 1);
                    in_pArray.push(pagingCurrentPage.value);
                    if(pagingCurrentPage.value + 1 !== pagingTotalNumber.value) in_pArray.push(pagingCurrentPage.value + 1);
                }
                if(pagingTotalNumber.value - pagingCurrentPage.value >= 3) in_pArray.push(0);
                in_pArray.push(pagingTotalNumber.value);
            }
            
            // console.log("pagingMovieMaxNumber:"+pagingMovieMaxNumber);
            // console.log("pagingCurrentPage:"+pagingCurrentPage);
            // console.log("pagingTotalNumber:"+pagingTotalNumber);

            pagingArray.value = in_pArray;
            // console.log(pagingArray);
            let in_fromNum = (pagingCurrentPage.value - 1) * pagingMovieMaxNumber.value;

            in_lists = in_lists.splice(in_fromNum,pagingMovieMaxNumber.value);
            //
            // moveToAllContents();
            // console.log("felteredLists end");
            return in_lists;
            // if(keyword == "") {
            //     return [];
            // }else {
            //     return lists;
            // }
        
    });
    //created
    (() => {//console.log("created");
        if(typeof(moviePageFlg) != "undefined") { //MOVIE PAGE
            if(!(typeof(QuerySearchKey) == "undefined" || QuerySearchKey == null)){
                keyword.value = QuerySearchKey;
            }else {
                keyword.value = "";
            }
            if(!(typeof(QueryCategory) == "undefined" || QueryCategory == null)){
                selectCategory.value = [QueryCategory];
            }
            if(!(typeof(QueryTagKey) == "undefined" || QueryTagKey == null)){
                selectTag.value = [QueryTagKey];
            }
            if(!(typeof(QueryMovieKey) == "undefined" || QueryMovieKey == null)){
                for (let i=0;i<lists.value.length;i++) {
                    if(lists.value[i].id == QueryMovieKey){
                        openModal(lists.value[i]);
                        break;
                    }
                }
            }
        }else { //TOP PAGE
            topKeyword.value = "";
        }

        window.addEventListener('resize',resizeNewTextHeight,false);

    })();

    onMounted(() => {
        initNewTextMoreButton();
    });
    onBeforeUnmount(() => {
        window.removeEventListener('resize',resizeNewTextHeight,false);
    });
</script>


<template>
            <header class="wrap-outer m-header-01" v-if="pageType === 'top'">
                <div class="wrap-inner contents">
                    <h1 class="m-logo-01"><a href="./">ファジアーノ岡山</a></h1>
                    <p class="m-desc-01">ファジアーノ岡山をもっと知ってもらい盛り上げていきたい！<br>試合日以外のワクワク継続用に関連情報をまとめて掲載していきます！！</p>
                    <!-- <div class="m-input-01"><div class="contents"><input type="text" id="fn-top-input-form" v-model="topKeyword" autocomplete="off" placeholder="サイト内を検索する"><a v-bind:href="getHref('keyword',topKeyword)"></a></div></div> -->
                </div>
            </header>
            <div class="wrap-outer main m-main-01" v-if="pageType === 'top'">
                <div class="wrap-inner m-main-01-contents">
                    
                    <div class="column-left m-menu-01 contents-left" v-bind:class="{opened:getMobileMenuOpen()}" v-on:click="setMobileMenuOpen(false)">
                        <div class="contents-leftBG" v-on:click="mobileMenuNoClose">
                            <a href="javascript:void(0)" class="m-button-04" v-on:click="setMobileMenuOpen(false)"></a>
                            <div class="title list">カテゴリ</div>
                            <ul class="list">
                                <li v-bind:class="{on:getCategory('ALL')}"><a href="javascript:void(0)" v-on:click="setCategory('ALL');">すべて</a></li>
                                <li v-for="clist in categoryList" v-bind:class="{on:getCategory(clist)}"><a v-on:click="setCategory(clist)" v-text="clist"></a></li>
                            </ul>
                            <div class="title tag">勝敗</div>
                            <ul class="check wl">
                                <li v-for="wlist in wlList" v-bind:class="{on:getWL(wlist)}"><a href="javascript:void(0)" v-on:click="setWL(wlist);" v-text="wlist"></a></li>
                            </ul>
                            <div class="title tag">タグ</div>
                            <ul class="check wl">
                                <li v-for="tlist in tagList" v-bind:class="{on:getTag(tlist)}"><a href="javascript:void(0)" v-on:click="setTag(tlist);" v-text="tlist"></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="column-right contents-right">
                        <!-- <h2 class="m-h2-01"><span>PICK UP</span><span class="sub">おすすめ動画</span></h2> -->
                        <!--<div class="m-cardbox-02">
                            <div v-for="news in newLists">
                                <div class="iframe-movie">
                                    <iframe width="640" height="360" v-bind:src="getYoutubePath(news.id)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div class="contents-text" id="fn-topText">
                                    <div class="m-cardbox-02-category" v-html="sanitizeHtml(getCategoryHTML(news.category))" v-bind:class="{off:!getDisplayCheck(news.tags)}"></div>
                                    <div class="m-cardbox-02-date" v-text="news.date"></div>
                                    <div class="m-cardbox-02-title" v-text="news.title"></div>
                                    <div class="m-cardbox-02-tags" v-html="sanitizeHtml(getTagHTML(news.tags))" v-bind:class="{off:!getDisplayCheck(news.tags)}"></div>
                                </div>
                                <div id="fn-pickupText" class="m-cardbox-02-text" v-html="sanitizeHtml(news.text)" ref="newText"></div>
                                <div>
                                    <a href="javascript:void(0)" class="button-more" id="fn-pickupMore">続きを見る</a>
                                    <a href="javascript:void(0)" class="button-close" id="fn-pickupClose" v-scroll-to="'#fn-topText'">閉じる</a>
                                </div>
                            </div>
                        </div>
                        -->
                        <!--<ul class="m-cardbox-01">
                            <li v-for="pick in pickupLists">
                                <a v-bind:href="getHref('mid',pick.id)"><img :src="getThumbnailURL(pick.id)"></a>
                                <div class="contents-text">
                                    <div class="m-cardbox-01-category" v-html="sanitizeHtml(getCategoryHTML(pick.category))" v-bind:class="{off:!getDisplayCheck(pick.tags)}"></div>
                                    <div class="m-cardbox-01-date" v-text="pick.date"></div>
                                    <a v-bind:href="getHref('mid',pick.id)"><div class="m-cardbox-01-title" v-text="pick.title" v-bind:title="pick.title"></div></a>
                                    <div class="m-cardbox-01-tags" v-html="sanitizeHtml(getTagHTML(pick.tags))" v-bind:class="{off:!getDisplayCheck(pick.tags)}"></div>
                                </div>                                
                            </li>
                        </ul> -->
                        <!-- <h2 class="m-h2-01" id="all-contents"><span>ALL CONTENTS</span><span class="sub">すべてのコンテンツ</span></h2> -->
                        <div class="m-selectForm-01">
                            <select v-model="sortSelected">
                                <option value="date_new">新しい順</option>
                                <option value="date_old">古い順</option>
                            </select>
                        </div>
                        <div class="m-count-01" v-bind:class="{off:!getCountVisibleCheck()}"><span v-text="getCurrentSearchNumber()"></span>/<span v-text="getSearchNumber()"></span>件</div>
                        <div class="m-count-01" v-bind:class="{off:getCountVisibleCheck()}"><span>0</span>件</div>
                        <div class="m-icondesc-view"><img src="/assets/img/icon-view.png">試合ハイライト</div>
                        <!-- <ul class="m-cardbox-01"> -->

                            <transition-group name="mlist" tag="ul" class="m-cardbox-01" v-bind:class="{off:getNoData()}">
                                <li v-for="list in filteredLists" :key="list.id" v-bind:class="{isview:getViewData(list.view)}">
                                    <!-- <a href="javascript:void(0)"> -->
                                        <a href="javascript:void(0)"v-on:click="openModal(list)"><img :src="getThumbnailURL(list.id)"></a>
                                        <div class="contents-text">
                                            <!-- <div class="m-cardbox-01-category" v-html="sanitizeHtml(getCategoryHTML(list.category))" v-bind:class="{off:!getDisplayCheck(list.tags)}"></div> -->
                                            <div class="m-cardbox-01-category" v-bind:class="{off:!getDisplayCheck(list.category)}">
                                                <span v-for="(n,i) in getCategoryListHTML(list.category)">
                                                    <span v-if="i > 0">、</span>
                                                    <a href="javascript:void(0)" v-text="n.category_name" v-on:click="setSelectCategory(n.category_key)"></a>
                                                </span>
                                            </div>
                                            <div class="m-cardbox-01-date" v-text="list.date"></div>
                                            <a href="javascript:void(0)"v-on:click="openModal(list)"><div class="m-cardbox-01-title" v-text="list.title" v-bind:title="list.title"></div></a>
                                            <div class="m-cardbox-01-tags" v-bind:class="{off:!getDisplayCheck(list.tags)}">
                                                <a href="javascript:void(0)" v-for="n in getTagListHTML(list.tags)" v-text="n.tag_name" v-on:click="setSelectTag(n.tag_key)" v-on:click.stop="eventStop"></a>
                                            </div>
                                            
                                            <!-- <div class="m-cardbox-01-text" v-html="sanitizeHtml(list.text)"></div> -->
                                            
                                        </div>
                                    <!-- </a> -->
                                </li>
                            </transition-group>
                        <!-- </ul> -->
                        
                        <div class="m-paging m-paging-01">
                            <ul>
                                <li class="nb back" v-bind:class="{on:getPagingBackCheck()}"><span v-on:click="pagingBack()" v-scroll-to="'#all-contents'"></span></li>
                                <!-- <li v-for="(page,index) in pagingTotalNumber"><span v-text="(index+1)" v-on:click="setPage(index+1)" v-scroll-to="'#all-contents'" v-bind:class="{on:getPagingStatus(index+1)}"></span></li> -->
                                <li v-for="(page,index) in pagingArray"><span class="dot" v-bind:class="{vis:getPagingDotCheck(page)}">…</span><span v-text="(page)" v-on:click="setPage(page)" v-scroll-to="'#all-contents'" v-bind:class="{on:getPagingStatus(page),off:getPagingDotCheck(page)}"></span></li>
                                <li class="nb next" v-bind:class="{on:getPagingNextCheck()}"><span v-on:click="pagingNext()" v-scroll-to="'#all-contents'"></span></li>
                            </ul>
                        </div>
                        <div class="m-message-01" v-bind:class="{on:getNoData()}">
                            データが一致しませんでした。
                        </div>
                    </div>
                </div>
            </div>
            <footer class="wrap-outer m-footer-01">
                <div class="wrap-inner">
                    <div>Copyright oshitoru.com 2024 All Rights Reserved.</div>
                </div>
            </footer>
            <a href="javascript:void(0)" class="m-button-03" v-on:click="setMobileMenuOpen(true)"></a>
            <a href="#" v-scroll-to="'body'" class="m-button-01" id="fn-page-top"></a>
            <modal-form v-bind:post="modalData" @p_modal-close="modalClose" v-if="pageType === 'top'"></modal-form>
            <modal-form v-bind:post="modalData" @p_modal-close="modalClose" @p_modal-close_tags="modalCloseSelectTag" @p_modal-close_category="modalCloseSelectCategory" v-else-if="pageType === 'search'"></modal-form>
</template>

<style scoped>

</style>