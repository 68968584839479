<script setup>
    import * as sanitizeHtml from 'sanitize-html'
    // import { defineProps, defineEmits } from 'vue';
    const props = defineProps(['post']);
    const emit = defineEmits(['p_modal-close','p_modal-close_tags','p_modal-close_category'])
    const modalClose = () => {
        // console.log("c_modalClose");
        // console.log(emit);
        // this.$emit("p_modal-close");
        emit('p_modal-close');
        // return false;
    }
    const modalCloseBG = () => {
        // console.log("c_modalCloseBG");
        emit('p_modal-close');
        // this.$emit("p_modal-close");
        return false;
    }
    const modalNoClose = (e) => {
        e.stopPropagation();
    }
    const getYoutubePath = (key) => {
        return "https://www.youtube.com/embed/" + key;
    }
    const getTagListHTML = (tag_str) => {
        // var tagData = {id:"aaaaaa",title:"title"};
        var tagData = [];
        var ar = (tag_str.indexOf(",") == (-1))?[tag_str]:tag_str.split(",");
        for(var i=0;i<ar.length;i++) {
            tagData.push({tag_key:ar[i], tag_name: ar[i]});
        }
        return tagData;
    }
    const getCategoryListHTML = (category_str) => {
        // var tagData = {id:"aaaaaa",title:"title"};
        var categoryData = [];
        var ar = (category_str.indexOf(",") == (-1))?[category_str]:category_str.split(",");
        for(var i=0;i<ar.length;i++) {
            categoryData.push({category_key:ar[i], category_name: ar[i]});
        }
        return categoryData;
    }
    const setSelectTag = (tag_key) => {
        // this.$emit("p_modal-close_tags",tag_key);
        emit('p_modal-close_tags',tag_key);
    }
    const setSelectCategory = (category_key) => {
        // this.$emit("p_modal-close_category",category_key);
        emit("p_modal-close_category",category_key);
    }
    const getDisplayCheck = (data) => {
        if(!data || data == "") return false;
        return true;
    }

</script>

<template>
    <div class="l-modal m-modal-01" v-bind:class="{on:post.on}" v-on:click="modalCloseBG">
        <div class="wrap-inner m-modal-01-inner m-cardbox-03" v-on:click="modalNoClose" v-if="post.on">
            <a href="javascript:void(0);" class="button-close" v-on:click="modalClose"></a>
            
            <!--<youtube :video-id="post.id"/>-->
            
            <div class="iframe-movie">
                <iframe width="640" height="360" v-bind:src="getYoutubePath(post.id)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="contents-text">
                <div class="m-cardbox-03-category" v-bind:class="{off:!getDisplayCheck(post.category)}">

                    <span v-for="(n,i) in getCategoryListHTML(post.category)">
                        <span v-if="i > 0">、</span>
                        <a href="javascript:void(0)" v-text="n.category_name" v-on:click="setSelectCategory(n.category_key)"></a>
                    </span>
                    <!-- <a href="javascript:void(0)" v-for="categoryData in getCategoryListHTML(post.category)" v-text="categoryData.category_name" v-on:click="setSelectCategory(categoryData.category_key)"></a> -->
                </div>
                <div class="m-cardbox-03-date" v-text="post.date"></div>
                <div class="m-cardbox-03-title" v-text="post.title"></div>
                
                <div class="m-cardbox-03-tags" v-bind:class="{off:!getDisplayCheck(post.tags)}">
                    <a href="javascript:void(0)" v-for="tagData in getTagListHTML(post.tags)" v-text="tagData.tag_name" v-on:click="setSelectTag(tagData.tag_key)"></a>
                </div>
                
                <div class="m-cardbox-03-text" v-html="sanitizeHtml(post.text)"></div>
            </div>
            <a href="javascript:void(0)" class="m-button-02" v-on:click="modalClose">閉じる</a>
        </div>
    </div>
</template>

